<template>
  <NuxtLink
    :to="localePath(url)"
    class="link"
    :class="{ 'has-custom-cursor': hasCustomCursor }"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
  >
    <img v-if="imageSrc" :src="imageSrc" :alt="title" class="image" />
    <div class="link-content">
      <h3 class="title">{{ title }}</h3>
      <div v-if="shortDescription" class="description">{{ shortDescription }}</div>
    </div>

    <div v-if="hoverAssetSrc" class="hover-asset-container">
      <video
        v-if="hoverAssetType === 'video'"
        ref="hoverVideo"
        :data-src="hoverAssetSrc"
        :data-poster="hoverVideoPostereSrc"
        loop
        autoplay
        playsinline
        muted
        class="hover-video"
      ></video>

      <img v-if="hoverAssetType === 'image'" :src="hoverAssetSrc" alt="" class="hover-image" />
    </div>
  </NuxtLink>

  <CustomCursor
    v-if="hasCustomCursor && showCustomCursor && !isMobileOrTablet"
    :outer-cloudinary-asset="customCursor.outer_cloudinary_asset"
    :inner-cloudinary-asset="customCursor.inner_cloudinary_asset"
  />
</template>

<script setup lang="ts">
import { AssetCloudinary } from '~/types/contentstack';
import CustomCursor from '~/components/CustomCursor.vue';
import { getFirstIndex } from '~/util/arrays';
import { useDeviceStore } from '~/store/device';

export type HlpCategoryModuleItemType = {
  referenceConnection: { edges: [{ node: { url: string; title: string; display_title: string } }] };
  image_cloudinary_asset: AssetCloudinary[];
  hover_image_or_video_cloudinary_asset: AssetCloudinary[];
  hover_video_poster_cloudinary_asset?: AssetCloudinary[];
  short_description: string;
  custom_cursor: {
    outer_cloudinary_asset: AssetCloudinary[];
    inner_cloudinary_asset: AssetCloudinary[];
    text: string;
  };
  overrides: {
    title?: string;
  };
};

const cloudinary = useCloudinary();
const localePath = useLocalePath();
const deviceStore = useDeviceStore();

const props = defineProps<{ item: HlpCategoryModuleItemType }>();

const showCustomCursor = ref(false);

const handleMouseOver = () => {
  showCustomCursor.value = true;
};

const handleMouseLeave = () => {
  showCustomCursor.value = false;
};

const { url, display_title: referenceTitle } = toRef(props.item.referenceConnection.edges[0].node)?.value;
const {
  short_description: shortDescription,
  overrides,
  image_cloudinary_asset: imageAsset,
  custom_cursor: customCursor,
} = toRef(props.item)?.value;

const imageResourceId = getFirstIndex(imageAsset)?.public_id;
const imageSrc = imageResourceId ? cloudinary.generateImageUrl(imageResourceId, { width: 148, height: 148 }) : null;

const transformOptions = { width: 464, height: 261 };
const hoverAsset = getFirstIndex(props.item?.hover_image_or_video_cloudinary_asset);
const hoverAssetSrc =
  hoverAsset?.resource_type === 'image'
    ? cloudinary.generateImageUrl(hoverAsset.public_id, transformOptions)
    : hoverAsset?.resource_type === 'video'
    ? cloudinary.generateVideoUrl(hoverAsset.public_id, transformOptions)
    : null;

const hasCustomCursor = !!getFirstIndex(props.item.custom_cursor.outer_cloudinary_asset)?.public_id;
const hoverAssetType = hoverAsset?.resource_type;
const title = overrides?.title || referenceTitle;

const hoverVideoPosterResourceId = getFirstIndex(props.item.hover_video_poster_cloudinary_asset)?.public_id;
const hoverVideoPostereSrc = hoverVideoPosterResourceId
  ? cloudinary.generateImageUrl(hoverVideoPosterResourceId, transformOptions)
  : undefined;

const hoverVideo = ref<HTMLVideoElement>();

const isMobileOrTablet = computed(() => deviceStore.device?.isMobileOrTablet === true);

let lazyLoadObserver: IntersectionObserver | null = null;
onMounted(() => {
  const isHoverSupported = window.matchMedia('(hover: hover)').matches;

  lazyLoadObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const video = hoverVideo.value;
        if (!video || !isHoverSupported) {
          return;
        }

        const poster = video.getAttribute('data-poster')!;
        if (poster) {
          video.setAttribute('poster', poster);
        }
        const src = video.getAttribute('data-src')!;
        video.setAttribute('src', src);

        video.load();

        lazyLoadObserver?.unobserve(entry.target);
      }
    });
  });

  if (hoverVideo.value) {
    lazyLoadObserver.observe(hoverVideo.value);
  }
});

onUnmounted(() => {
  // Clean up the observer when the component is unmounted
  if (lazyLoadObserver) {
    lazyLoadObserver.disconnect();
    lazyLoadObserver = null;
  }
});
</script>

<style lang="scss" scoped>
.link {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  gap: 1.5rem;
  border-radius: 8px;
  background: #f9f9f9;
  color: #595959;
  width: 100%;
  transition: color 0.5s ease-out, background-color 0.5s ease-out;

  @include local-mixins.desktop_and_tablet {
    padding: 1rem;
  }
}

.link-content {
  display: flex;
  flex-direction: column;
}

.link:hover {
  color: #fff;
  background: #222;
  z-index: 10;
}

.has-custom-cursor:has(+ .custom-cursor[data-is-loaded='true']) {
  cursor: none;
}

.title {
  text-transform: uppercase;
  font-family: local-vars.$ff-hlp;
  font-size: 1.375rem;
  line-height: 1;
  white-space: nowrap;
  margin: 0;

  @include local-mixins.small-mobile {
    font-size: 1rem;
  }

  @include local-mixins.tablet {
    font-size: 3rem;
  }
  @include local-mixins.desktop {
    font-size: 5rem;
  }
}

.description {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  white-space: nowrap;
  display: none;
}

@include local-mixins.desktop {
  .title:has(+ .description) {
    transition: all 0.5s ease-out;
    transform: translateY(1rem);
  }

  .description {
    transition: all 0.5s ease-out;
    transition-delay: 0.1s;
    transform: translateY(1rem);
    opacity: 0;
  }

  .link:hover {
    .title {
      transform: translateY(0);
    }
    .description {
      display: block;
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.image {
  width: 100px;
  height: 100px;

  @include local-mixins.desktop_and_tablet {
    width: 148px;
    height: 148px;
  }
}

.hover-asset-container {
  display: grid;
  position: absolute;
  min-width: 464px;
  width: 34%;
  aspect-ratio: 16 / 9;
  background: #000;
  right: 4%;
  overflow: hidden;
  border-radius: 8px;
  clip-path: inset(0% 0% 100% 0%);
  transition: clip-path 0.5s ease, opacity 0.2s ease-out;
  opacity: 0;
}

.hover-video,
.hover-image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

@include local-mixins.desktop {
  .link:hover .hover-asset-container {
    opacity: 1;
    clip-path: inset(0);
  }
}
</style>
